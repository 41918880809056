import { Link } from 'gatsby'
import AppBorder from '../components/AppBorder'
import Layout from '../components/Layout'
import React from 'react'
import SEO from '../components/Seo'

class NotFoundPage extends React.Component {

  render() {
    return (
      <Layout>
        <SEO title='Not found - Daniel Swaine' />
        <AppBorder title='Error! Not found' colour='#fff'>
          <h2>Oh dear...</h2>
          <p>It seems that we have hit a problem and cannot find that page.</p>
          <Link to='/'>Take me to the home page...</Link>
        </AppBorder>
      </Layout>
    )
  }
}

export default NotFoundPage;
